@keyframes zoomIn {
  0% {
      transform: scale(.1);
      translate: var(--click_x) var(--click_y);
  }
  to {
      transform: scale(1);
  }
}

@keyframes zoomOut {
  0% {
      transform: scale(1);
  }
  to {
      transform: scale(.2);
      translate: var(--click_x) var(--click_y);
  }
}

@keyframes slide-out-teleport-slide-in {
  0% {
      transform: translateX(0);
      opacity: 1;
  }
  48%, 50% {
      transform: translateX(100vw);
      opacity: 0;
  }
  50.01%, 52% {
      transform: translateX(-100vw);
  }
  100% {
      opacity: 1;
  }
}

@keyframes slide-out-teleport-slide-in-reverse {
  0% {
      transform: translateX(0);
      opacity: 1;
  }
  48%, 50% {
      transform: translateX(-100vw);
      opacity: 0;
  }
  50.00001%, 52% {
      transform: translateX(100vw);
  }
  100% {
      opacity: 1;
  }
}

.fade-out {
  opacity: 1;
  animation: fadeOut 0s forwards;
}

.fade-out-slow {
  opacity: 1;
  animation: fadeOut 0.5s forwards ease-in-out;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 0.25s forwards ease-in-out;
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.zoom-out-animation,
.zoom_in_animation {
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.zoom_in_animation {
  animation-name: zoomIn;
}

.zoom-out-animation {
  animation-fill-mode: backwards;
  animation-name: zoomOut;
}