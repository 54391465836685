@keyframes slideUp {
    from { transform: translateY(100%) }
    to { transform: translateY(0) }
}

@keyframes slideDown {
    from { transform: translateY(0) }
    to { transform: translateY(100%) }
}

.slide-up,
.slide-down {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    z-index: 100;
}

.slide-up { animation-name: slideUp; }
.slide-down { animation-name: slideDown; }

.about-me-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.897);
    position: fixed;
    z-index: 10;
}

.about-me-background {
    background-image: linear-gradient(rgba(0, 0, 0, 0.01) 3px, #1b1b1b 2px);
    background-size: 8px 5px;
    position: fixed;
    left: 0;
    bottom: 10vh;
    width: 100vw;
    height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: -1;
}

.back-button {
    background-image: linear-gradient(0deg, #b9b9b9 0, #f3f3f3 25% 75%, #b9b9b9 100%);
    border-radius: 20%/50%;
    font-family: "WiiFont", sans-serif;
    color: #4b4b4b;
    height: 5vh;
    width: 15vh;
    max-width: 35vh;
    position: absolute;
    bottom: 2.5vh;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    line-height: 5vh;
    font-size: 3vh;
    z-index: 140;
    transition: transform 0.25s ease;
    cursor: pointer;
}

.back-button:before { content: "Back"; }
.back-button:hover { transform: translate(-50%, 0) scale(1.1); }

.mii-text {
    color: #fff;
    font-family: "WiiFont", sans-serif;
    font-size: 6vh;
    position: relative;
    text-align: center;
    z-index: 160;
    top: 2vh;
    left: 37vw;
}

.mii-text:before { content: "Mii"; }

.about-me-title,
.contact-me-title {
    font-family: "WiiFont", sans-serif;
    color: #fff;
    z-index: 200;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-me-title {
    font-size: 6vh;
    padding-top: 20px;
}

.contact-me-title {
    font-size: 5vh;
    padding-top: 50px;
}

.about-me-element-container {
    color: #fff;
    z-index: 200;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: clamp(20px, 5vw, 60px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    position: relative;
    box-sizing: border-box;
  }
  
  /* Add responsive breakpoints */
  @media screen and (max-width: 1024px) {
    .about-me-element-container {
      width: 95%;
      padding: clamp(15px, 4vw, 40px);
    }
  }
  
  @media screen and (max-width: 768px) {
    .about-me-element-container {
      width: 100%;
      padding: clamp(10px, 3vw, 30px);
    }
  }
  
  @media screen and (max-width: 480px) {
    .about-me-element-container {
      padding: 15px;
      gap: 1.5rem;
    }
  }

.linkedin-button,
.github-button,
.email-button {
    display: inline-block;
    width: 15vw;
    height: 15vw;
    background-size: cover;
    transition: transform 0.25s ease;
}

.linkedin-button { background-image: url(../public/assets/icons/linkedin.png); }
.github-button { background-image: url(../public/assets/icons/github.png); }
.email-button { background-image: url(../public/assets/icons/email.png); }

.email-button:hover,
.github-button:hover,
.linkedin-button:hover {
    transform: scale(1.05);
}

.contact-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    justify-items: center;
    height: 50vh;
}

.contact-grid-wrapper {
    max-width: 50%;
    margin: 0 auto;
}

.about-me-markdown {
    color: #fff;
    margin: auto;
    font-size: 2vh;
}

.about-me-markdown h1,
.about-me-markdown h2,
.about-me-markdown h3,
.about-me-markdown h4,
.about-me-markdown h5,
.about-me-markdown h6,
.about-me-markdown p,
.about-me-markdown li {
    color: #fff;
}

.about-me-markdown img { display: inline-block; }
.about-me-markdown code { font-size: 1em; }
.about-me-markdown a { color: #00c3ffa4; }

.about-me-markdown strong {
    padding: 1em;
    border-radius: 5%;
    background: #2e2e2ea8;
    font-family: 'Courier New', monospace;
}