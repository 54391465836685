body,
html {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

body::-webkit-scrollbar {
  display: none;
}

iframe {
  aspect-ratio: 16/9;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.game_container {
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  overflow: hidden;
  border-color: #000;
  justify-content: center;
  align-items: center;
}

.borderless-iframe {
  border: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 1em;
  background-color: #000000d5;
}

::-webkit-scrollbar-thumb {
  background-color: #646464ab;
  border-radius: 20px;
}

:root {
  --click_x: 0;
  --click_y: 0;
}

.no-overflow {
  overflow: hidden;
}

.overlay,
body::before {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

body {
  background: linear-gradient(0deg, #002839, #1d1d1d);
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  color: white;
  position: relative;
}

body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.2) 3px, transparent 3px),
    linear-gradient(rgba(0, 0, 0, 0.2) 2px, transparent 2px);
  background-size: 100% 2px, 100% 5px;
  pointer-events: none;
}

.overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 10;
  animation: fadeIn 0.25s forwards;
  transition: 0.25s;
  background: #000000b6;
  width: 100%;
  height: 100%;
}

.navigation_border {
  background-color: #00c3ffa4;
  bottom: 0;
  clip-path: polygon(
    0 100%,
    0 0,
    18%0,
    19% 1%,
    20% 3%,
    21% 6%,
    28% 35%,
    29% 38%,
    30% 39%,
    32% 40%,
    68% 40%,
    70% 39%,
    71% 38%,
    72% 35%,
    79% 6%,
    80% 3%,
    81% 1%,
    82%0,
    100%0,
    100% 100%,
    20% 100%
  );
  height: 18vh;
  left: 0;
  padding: 10px;
  width: 100%;
}

.date,
.navigation,
.navigation_border,
.time {
  display: flex;
  justify-content: center;
  position: fixed;
}

.navigation {
  background-image: linear-gradient(
    0deg,
    #272727 0,
    #292929d7 50%,
    #202020da 100%
  );
  bottom: -0.5vh;
  clip-path: polygon(
    0 100%,
    0 0,
    18%0,
    19% 1%,
    20% 3%,
    21% 6%,
    28% 35%,
    29% 38%,
    30% 39%,
    32% 40%,
    68% 40%,
    70% 39%,
    71% 38%,
    72% 35%,
    79% 6%,
    80% 3%,
    81% 1%,
    82%0,
    100%0,
    100% 100%,
    20% 100%
  );
  height: 18vh;
  left: 0;
  padding: 10px;
  width: 100%;
}

.date,
.time {
  align-items: center;
  color: #c0c0c0;
  pointer-events: none;
}

.time {
  bottom: 12vh;
  font-family: "DigitalFont", sans-serif;
  font-size: 4.5vh;
  height: 11vh;
  width: 100%;
}

.date {
  bottom: 2vh;
  font-size: 4vh;
  height: 12vh;
  width: 100%;
}

.text {
  color: #e9e9e9;
  font-family: "WiiFont", sans-serif;
  font-size: 3vh;
  font-weight: 700;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 10px;
  transform: translateX(-50%);
  width: 60%; /* Adjust as needed */
  max-width: 800px; /* Set a maximum width */
  word-wrap: break-word; /* Allow long words to be broken and wrapped */
  white-space: normal; /* Allow wrapping on whitespace */
}

.channel-grid {
  display: grid;
  gap: 25px;
  grid-template-columns: repeat(auto-fit, minmax(35vh, 1fr));
  justify-items: center;
  padding: 100px;
  padding-bottom: 25vh;
  padding-top: 8vh;
  position: relative;
  height: 100%;
}

/* Hiding scrollbar in Firefox */
html {
  scrollbar-width: none;
}

/* Hiding scrollbar in WebKit browsers (Chrome, Safari, etc.) */
.channel-grid::-webkit-scrollbar {
  display: none;
}

.channel-grid-item img,
.channel-grid-item:before {
  height: 100%;
  position: absolute;
  width: 100%;
}

.channel-grid-item img {
  border-radius: 12%;
  left: 0;
  object-fit: cover;
  top: 0;
}

.channel-grid-item {
  border-radius: 50%/10%;
  overflow: hidden;
  padding-bottom: calc(45% + 10px);
  position: relative;
  transition: transform 0.5s ease;
  width: 95%;
  cursor: pointer;
}

.channel-grid-item:before {
  left: 50%;
  opacity: 0.8;
  pointer-events: none;
  top: 50%;
}

.channel-grid-item:hover {
  border-radius: 50%/10%;
  box-shadow: inset 0 0 0 10px #00c3ffa4, 0 0 15px #00c3ffe3;
  transform: scale(1.025);
}

.selected {
  border-width: 1px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .game_container {
    height: auto;
    min-height: 100vh;
  }

  .channel-grid {
    padding: 50px;
    padding-bottom: 20vh;
    padding-top: 5vh;
  }

  .navigation,
  .navigation_border {
    height: 15vh;
  }

  .time {
    font-size: 3vh;
    bottom: 8vh;
  }

  .date {
    font-size: 3vh;
    bottom: 1vh;
  }

  .text {
    font-size: 3vh;
    width: 80%;
    top: 5px;
  }
}

@media (max-width: 480px) {
  .channel-grid {
    gap: 15px;
    padding: 20px;
    padding-bottom: 15vh;
    padding-top: 5vh;
  }

  .navigation,
  .navigation_border {
    height: 12vh;
  }

  .time {
    font-size: 3vh;
    bottom: 6vh;
  }

  .date {
    font-size: 3vh;
    bottom: 0vh;
  }

  .text {
    font-size: 3vh;
    width: 90%;
    top: 5px;
  }

  .channel-grid-item {
    padding-bottom: calc(50% + 10px);
  }
}



