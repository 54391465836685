body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.markdown-content {
  color: #fff;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.8);
  font-size: 2.5vh;
  padding: 1vh 20vw;
}

.toc-parent {
  display: flex;
  justify-content: center;
}

.toc-container {
  padding: 2vh 4vh;
  color: #adadad00;
  margin: auto;
  font-size: 1.5vh;
  display: inline-block;
}

.grid-container {
  display: grid;
  gap: 1em;
  padding-bottom: 3vh;
  grid-template-columns: repeat(auto-fit, minmax(47%, 1fr));
}

.left-cell, .right-cell {
  flex: 1;
  max-height: 100%;
}

.right-cell {
  overflow: hidden;
  background-color: #000000;
  border: 2px solid #555164;
}

a:link, a:visited, a:hover, a:active {
  text-decoration: none;
  font-style: italic;
}

a:link { color: rgb(241, 241, 241); }
a:visited { color: rgb(76, 76, 76); }
a:hover { color: rgb(143, 143, 143); }
a:active {
  text-decoration: underline;
  color: rgb(0, 150, 137);
}

.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6,
.markdown-content p,
.markdown-content li {
  color: #fff;
}

.markdown-content strong {
  padding: 1px;
  border-radius: 0%;
  background: #333333a8;
  font-family: "Courier New", monospace;
}

.fade-in-blogpost {
  opacity: 0;
  animation: fadeIn 0.3s forwards ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.blog-title-selected {
  font-size: 6vh;
  font-family: "WiiFont", sans-serif;
  color: #fff;
  padding: 3vh 0;
  text-align: center;
  margin-top: 2vh;
}

.TOC-title {
  font-size: 4vh;
  font-family: "WiiFont", sans-serif;
  color: #ffffff;
  text-align: center;
}

.date-selected {
  font-size: 3vh;
  font-family: "WiiFont", sans-serif;
  color: #fff;
  text-align: center;
  padding: 1.5vh;
}

.blog-tagline-selected {
  font-size: 3vh;
  font-family: "WiiFont", sans-serif;
  color: #fff;
  padding: 1vh 3vh 0;
}

.horizontal-line {
  padding-top: 2vh;
  border-top: 5px solid #fff;
}

.blog-post-tags {
  padding-top: 2vh;
  display: flex;
  justify-content: center;
}

.blog-post-tag-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.blog-post-tag {
  background-color: #33333379;
  padding: 8px 12px;
  border-radius: 4px;
  text-align: center;
  font-size: 2vh;
  color: #ddd;
}

.code-block {
  position: relative;
  margin-bottom: 1rem;
  font-family: 'Fira Code', monospace;
}

.long-code .code-content {
  max-height: 25vh;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.long-code.scrollable .code-content {
  overflow-y: auto;
}

.long-code .code-content.expanded {
  max-height: none;
}

.code-toggle {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #444;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 0.8rem;
  z-index: 1;
  font-family: 'Fira Code', monospace;
}

.code-toggle:hover {
  background-color: #555;
}

.code-content pre {
  margin: 0;
}

.code-content code {
  font-family: 'Fira Code', monospace !important;
  font-size: 14px;
  line-height: 1.5;
}

.long-code.scrollable .code-content::-webkit-scrollbar {
  width: 8px;
}

.long-code.scrollable .code-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.long-code.scrollable .code-content::-webkit-scrollbar-thumb {
  background: #888;
}

.long-code.scrollable .code-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.markdown-content code {
  font-size: 1.5vh;
}

.winxp-window-container {
  text-align: center;
}

.winxp-window {
  background: #0000003f;
  border: 1px solid #9c1c9c;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  display: inline-block;
  overflow: hidden;
}

.winxp-title-bar {
  background: linear-gradient(to bottom, #9c1c9c, #ff55ff);
  color: white;
  font-family: 'Tahoma', sans-serif;
  font-size: 1.25vh;
  font-weight: bold;
  padding: 2px 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.winxp-title {
  display: flex;
  align-items: center;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.winxp-favicon {
  width: 1.75vh;
  height: 1.75vh;
  margin-right: 0.5vh;
  object-fit: contain;
}

.winxp-filename {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.winxp-buttons {
  display: flex;
}

.winxp-button {
  width: 1.25vh;
  height: 1.25vh;
  margin-left: 2px;
  border: 1px solid #fff;
  border-radius: 0.5vh;
}

.winxp-minimize { background: #ffcc29; }
.winxp-maximize { background: #49dcdc; }
.winxp-close { background: #b967ff; }

.winxp-content {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0; /* Removes any unwanted vertical spacing */
}

.winxp-content img {
  display: block;
  max-width: 100%;
  height: auto;
}

.blog-word-count-centered {
  font-size: 1.5vh;
  color: #888;
  text-align: center;
  margin-top: 2vh;
  font-family: 'Tahoma', sans-serif;
}

.summary-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 1000px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .markdown-content {
    font-size: 2vh;
    padding: 5vh 5vw;
  }

  .blog-title-selected {
    font-size: 5vh;
    padding: 2vh 0;
  }

  .TOC-title,
  .blog-tagline-selected {
    font-size: 2.5vh;
    padding: 0.5vh;
  }

  .date-selected {
    font-size: 2vh;
  }

  .horizontal-line {
    padding-top: 1.5vh;
    border-top: 3px solid #fff;
  }

  .blog-post-tag-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .markdown-content {
    font-size: 2vh;
    padding: 5vh 10vw;
  }

  .blog-title-selected {
    font-size: 5vh;
    padding: 1vh 0;
  }

  .horizontal-line {
    padding-top: 1vh;
    border-top: 2px solid #fff;
  }

  .blog-post-tag-grid {
    grid-template-columns: 1fr;
  }

  .blog-post-tag {
    font-size: 2vh;
    padding: 6px 10px;
  }
}

.winxp-content.toc-content {
  background-color: #fff;
  color: #000;
  padding: 10px;
  max-height: 300px;
  overflow-y: auto;
}

.toc-item {
  margin-bottom: 5px;
}

.toc-item a {
  text-decoration: none;
  color: #0000FF;
}

.toc-item a:hover {
  text-decoration: underline;
}

.level-1 { margin-left: 0; }
.level-2 { margin-left: 15px; }
.level-3 { margin-left: 30px; }

.winxp-content.toc-content::-webkit-scrollbar {
  width: 16px;
}

.winxp-content.toc-content::-webkit-scrollbar-track {
  background: #fff;
  border-left: 1px solid #d4d4d4;
}

.winxp-content.toc-content::-webkit-scrollbar-thumb {
  background: #dfdfdf;
  border: 1px solid #b4b4b4;
  border-radius: 0;
}

.winxp-content.toc-content::-webkit-scrollbar-thumb:hover {
  background: #d0d0d0;
}


.header-highlight {
  animation: highlightPulse 1s ease-in-out;
}

@keyframes highlightPulse {
  0% { 
    transform: scale(1);
  }
  50% { 
    color: rgb(0, 255, 0);
    transform: scale(1.02);
  }
  100% { 
    transform: scale(1);
  }
}