.blogList_container {
  height: 100vh;
  justify-content: center;
  overflow: auto;
  top: -50vh;
  transform: translate(-50%, 0);
  width: 100vw;
}

.blogList_title {
  color: #fff;
  padding-top: 25px;
  font-family: "WiiFont", sans-serif;
  font-size: 6vh;
  position: relative;
  text-align: center;
  z-index: 10;
  transform: translate(10vw, 0);
  max-width: 80vw;
}

.blogList_summary {
  color: #fff;
  font-size: 2.5vh;
  position: relative;
  text-align: center;
  padding-bottom: 2vh;
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
  width: min(70vw, 70vh);
}

.bloglist_overlay {
  background-color: #0000006c;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  left: 0;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
}

.blogListContainer,
.wii_menu_button:before {
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog-post:hover {
  transform: scale(1.02);
}

.wii_button_container {
  width: 12vw;
  height: 15vh;
  left: 5%;
  top: 45%;
  position: absolute;
  background-image: linear-gradient(
    #dddddd00 0,
    #4d4d4dc9 2%,
    #504f4f75 6%,
    #363636b7 10%,
    #363636b7 90%,
    #504f4f75 94%,
    #4d4d4dc9 98%,
    #dddddd00 100%
  );
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.wii_button_container::before {
  content: "";
  height: 15vh;
  width: 15vh;
  background-image: radial-gradient(
    ellipse,
    #ddd 0,
    #ddd 58%,
    #ccc 64%,
    #ddddddd2 69%,
    #dddddd70 70%
  );
  transform: translate(-50%, -50%);
}

.msg_button_container,
.wii_button,
.wii_button_container {
  transform: translate(-50%, -50%);
  transition: transform 0.5s ease;
}

.wii_button,
.wii_button_container::before {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  left: 100%;
  position: absolute;
  top: 50%;
}

.wii_button {
  background-image: radial-gradient(ellipse at center, #dddddd69 60%, #aaa 80%),
    url(../public/assets/icons/mii_cropped.png);
  cursor: pointer;
  border: 3px solid #00c3ffa4;
  height: 12vh;
  width: 12vh;
}

.msg_button:hover,
.wii_button:hover {
  transform: translate(-50%, -50%) scale(1.1);
}

.msg_button_container {
  width: 12vw;
  height: 15vh;
  right: 0; /* Align to the right side of the screen */
  top: 45%;
  background-image: linear-gradient(
    #dddddd00 0,
    #4d4d4dc9 2%,
    #504f4f75 6%,
    #363636b7 10%,
    #363636b7 90%,
    #504f4f75 94%,
    #4d4d4dc9 98%,
    #dddddd00 100%
  );
  position: absolute;
  transform: translateY(-50%); /* Center vertically */
}

.blogList_container,
.msg_button,
.msg_button_container,
.msg_button_container::before {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
}

.msg_button_container::before {
  content: "";
  height: 15vh;
  width: 15vh;
  background-image: radial-gradient(
    ellipse,
    #ddd 0,
    #ddd 58%,
    #ccc 64%,
    #ddddddd2 69%,
    #dddddd70 70%
  );
}

.blogList_container,
.msg_button {
  transition: transform 0.5s ease;
}

.msg_button,
.msg_button_container::before {
  border-radius: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.msg_button {
  background-image: radial-gradient(
      ellipse at center,
      #dddddd69 60%,
      #aaaaaac9 80%
    ),
    url(../public/assets/icons/mail.png);
  border: 3px solid #00c3ffa4;
  height: 12vh;
  width: 12vh;
  cursor: pointer;
  position: absolute;
  top: 50%; /* Center vertically within the container */
  transform: translate(-50%, -50%); /* Center both horizontally and vertically */
}

.return_to_menu_bar,
.wii_menu_button {
  background-position: center;
  transform: translate(-50%, 0);
  transition: transform 0.5s ease;
}

.return_to_menu_bar {
  background-image: linear-gradient(#e7e7e7 3px, #f2f2f2 2px);
  background-size: 8px 5px;
  border: 0.5vh solid #585858a4;
  height: 10vh;
  left: 0;
  position: fixed;
  top: 40vh;
  width: 100vw;
  z-index: 30;
}

.wii_menu_button {
  height: min(200px, 10vw);
  width: min(200px, 10vw);
  position: fixed;
  padding: min(200px, 2vw);
  cursor: pointer;
  z-index: 40;
  display: flex;
  align-items: center;
  transform: translate(-50vw, -50vh);
}

@keyframes oscillate {
  0% {
    transform: translate(-50vw, -50vh) scale(1);
  }

  50% {
    transform: translate(-50vw, -50vh) scale(1.1);
  }

  100% {
    transform: translate(-50vw, -50vh) scale(1);
  }
}

.wii_menu_button::before {
  background-image: url("../public/assets/icons/arrow.svg");
  content: "";
  background-repeat: no-repeat;
  filter: invert(100%);
  background-size: cover;
  display: inline-block;
  height: min(150px, 8vw);
  width: min(150px, 8vw);
  transition: transform 0.3s ease;
  margin-top: 10vh;
}

@media (max-width: 768px) {
  .wii_menu_button {
    height: min(150px, 8vw);
    width: min(150px, 8vw);
    margin-top: 5vh;
  }
}

.swipe_button_left,
.swipe_button_right {
  height: 0;
  width: 0;
  position: fixed;
  top: -10vh;
  cursor: pointer;
  z-index: 40;
  display: flex;
  align-items: center;
  border-top: 40px solid transparent;
  border-bottom: 40px solid transparent;
  bottom: 60vh;
  transition: transform 0.25s ease;
}

@keyframes left_oscillate {
  0% {
    transform: translate(-50%, 0);
    left: -48vw;
  }

  50% {
    transform: translate(-50%, 0);
    left: -47.5vw;
  }

  100% {
    transform: translate(-50%, 0);
    left: -48vw;
  }
}

@keyframes right_oscillate {
  0% {
    transform: translate(-50%, 0);
    left: 48vw;
  }

  50% {
    transform: translate(-50%, 0);
    left: 47.5vw;
  }

  100% {
    transform: translate(-50%, 0);
    left: 48vw;
  }
}

.swipe_button_right {
  right: 25px;
  border-left: 40px solid #00c3ffa4;
  clip-path: polygon(100% 50%, 0 100%, 25% 50%, 0 0);
  transform: translate(50vw, 0);
}

.swipe_button_right:hover {
  transform: translate(50.25vw, 0);
}

.swipe_button_left {
  left: 25px;
  border-right: 40px solid #00c3ffa4;
  clip-path: polygon(100%0, 75% 50%, 100% 100%, 0 50%);
  transform: translate(-50vw, 0);
}

.swipe_button_left:hover {
  transform: translate(-50.25vw, 0);
}

.blogPost_grid {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: 1fr; /* Modified property */
  justify-items: center;
  margin-top: -8vh;
  position: relative;
  padding: 8vh 4vw 5vh;
}

.blog-post {
  background-color: #353535e0;
  border-radius: 10px;
  padding-left: 3vw;
  padding-right: 3vw;
  padding-bottom: 15px;
  z-index: 99;
  transition: transform 0.5s ease;
  /* max-width: 50vw; */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.719);
  cursor: pointer;
  width: min(70vw, 70vh);
}

.blog-title {
  font-size: 2.5vh;
  color: #ffffff;
  margin-bottom: 20px;
}

.blog-excerpt {
  font-size: 1.75vh;
  color: #dbdbdb;
  margin-bottom: 20px;
  position: relative;
  padding-left: 25px;
}

.blog-excerpt::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  background-color: #a8a8a8;
  border-radius: 20%;
}

.blog-details {
  margin-top: 10px;
}

.blog-date {
  font-size: 1.25vh;
  color: #c2c2c2;
  text-align: right;
}

.blog-tags {
  margin-top: 10px;
}

.tag-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.tag {
  background-color: #7c7c7c;
  border: 1px solid #cccccc00;
  border-radius: 4px;
  text-align: center;
  font-size: 1.5vh;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2px 10px;
}

.button-fade-out {
  opacity: 1;
  animation: fadeOut 0.5s forwards ease-in-out;
  pointer-events: none;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .msg_button,
  .wii_button {
    height: 100px;
    width: 100px;
  }

  .wii_button_container,
  .msg_button_container{
    height: 120px;
  }

  .wii_button_container:before,
  .msg_button_container:before{
    height: 120px;
    width: 120px;
  }
}

@media (max-width: 480px) {
  .msg_button,
  .wii_button {
    height: 60px;
    width: 60px;
  }

  .wii_button_container,
  .msg_button_container{
    height: 80px;
  }

  .wii_button_container:before,
  .msg_button_container:before{
    height: 80px;
    width: 80px;
  }
}

.blog-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blog-date, .blog-word-count {
  font-size: 1.75vh;
  color: #888;
}

.blog-word-count {
  margin-left: auto;
}